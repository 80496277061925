/* eslint-disable */

import React, { useState } from 'react'
import { Form, Formik } from 'formik'

import Input from './common/Input'
import Button from './common/Button'
import { required } from '../utils/validators'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { isEmpty } from "lodash";

export default function NoteForm({ onCancel, onSubmit, initialValues = {} }) {
    const [description, setDescription] = useState(initialValues && !isEmpty(initialValues.description) ? initialValues.description : '')

    function handleSubmit(data, actions) {
        const newData = new FormData()

        newData.append('note', data.note)
        newData.append('description', description)

        onSubmit(newData, actions)
    }
    return (
        <Formik onSubmit={handleSubmit} initialValues={{ note: '', description: '', extraNotes: [], ...initialValues }}>
            {({ values }) => (
                <Form>
                    <Input name="note" label="Примечание" validate={required}/>

                    {/*<Input component="textarea" name="description" label="Описание" validate={required} />*/}

                    <div style={{ height: 250, margin: '20px 0 30px 0' }}>

                        <ReactQuill
                            className="ql-container pb-4"
                            theme="snow"
                            value={description}
                            onChange={setDescription}
                        />
                    </div>
                    {/*<FieldArray name="extraNotes">*/}
                    {/*    {({ push, remove }) => (*/}
                    {/*        <div style={{ marginBottom: 16 }}>*/}
                    {/*            {values.extraNotes.map((_, index) => (*/}
                    {/*                <div key={index} style={{*/}
                    {/*                    display: 'flex',*/}
                    {/*                    width: '100%',*/}
                    {/*                    alignItems: 'start',*/}
                    {/*                    gap: 8,*/}
                    {/*                    marginBottom: 8*/}
                    {/*                }}>*/}
                    {/*                    <div style={{ width: '100%' }}>*/}
                    {/*                        <Input label={`Дополнительное примечание ${index + 1}`}*/}
                    {/*                               name={`extraNotes[${index}]`} validate={required}/>*/}
                    {/*                    </div>*/}
                    {/*                    <Button icon="trash-outline" className="is-danger flex-1 mt-5"*/}
                    {/*                            onClick={() => remove(index)} text=""/>*/}
                    {/*                </div>*/}
                    {/*            ))}*/}

                    {/*            <Button text="Добавить примечание" icon="add-outline" className="is-success"*/}
                    {/*                    onClick={() => push('')} type="button"/>*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*</FieldArray>*/}

                    <div className="is-pulled-right">
                        <Button onClick={onCancel} icon="close-outline" text="Отмена" className="is-danger"/>
                        &nbsp;&nbsp;
                        <Button text="Сохранить" icon="add-outline" type="submit" className="is-success"/>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
