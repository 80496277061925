import React from 'react'
import { usePostRequest } from '../hooks/request'
import { NOTE_LIST } from '../urls'
import { useMessage } from '../hooks/message'
import NoteForm from './NoteForm'

export default function NoteCreate({ onCancel, reload }) {
    const category = usePostRequest({ url: NOTE_LIST })
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const { success } = await category.request({ data })

        if (success) {
            showMessage('Успешно сохранен', 'is-success')
            reload.request()
            onCancel()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered"><b>Добавить</b></h1>

            <NoteForm
                onCancel={onCancel}
                onSubmit={onSubmit} />
        </div>
    )
}
