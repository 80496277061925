import React, { useState } from 'react'

import Table from '../components/common/Table'
import { useLoad } from '../hooks/request'
import { NOTE_LIST } from '../urls'
import { useModal } from '../hooks/modal'
import { useQueryParams } from '../hooks/queryString'
import NoteCreate from '../components/NoteCreate'
import Layout from '../components/Layout'
import Button from '../components/common/Button'
import NoteItem from '../components/NoteItem'

export default function Note() {
    const params = useQueryParams()
    const [page, setPage] = useState(1)
    const territory = useLoad({ url: NOTE_LIST, params: { ...params, page } }, [params, page])

    const [showUpdateModal, setShowUpdateModal] = useModal(<NoteCreate reload={territory} onCancel={() => setShowUpdateModal()} />)

    return (
        <Layout>
            <Button text="Добавить" icon="add-outline" className="is-link is-outlined ml-2 mb-2" onClick={showUpdateModal} />

            <Table
                loading={territory.loading}
                items={territory.response ? territory.response.results : []}
                columns={{ id: '№', name: 'Примечание', courierName: 'Описание', buttons: '' }}
                totalCount={territory.response ? territory.response.count : 0}
                pageSize={15}
                activePage={page}
                onPageChange={setPage}
                renderItem={(item, index) => <NoteItem key={item.id} index={index} item={item} reload={territory} />}
            />
        </Layout>
    )
}
