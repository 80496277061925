/* eslint-disable */

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import React, { useCallback, useState } from 'react'
import { FieldArray, Form, Formik } from 'formik'
import { debounce } from 'lodash'
import { StyleSheet } from 'aphrodite'

import Button from './common/Button'
import Input from './common/Input'
import Card from './common/Card'
import { format } from '../utils/number'
import { useLoad } from '../hooks/request'
import { PRODUCT_DETAIL, PRODUCT_LIST, PRODUCT_LOCATION_LIST } from '../urls'
import { getMeasurement } from '../utils/types'
import Table from './common/Table'
import { required } from '../utils/validators'
import { useModal } from '../hooks/modal'
import DeleteModal from './common/DeleteModal'
import NewSupplierSelect from './common/NewSupplier'
import DocumentsProductUpdate from './DocumentsProductUpdate'
import DocumentsProductCreate from './DocumentsProductCreate'

pdfMake.vfs = pdfFonts.pdfMake?.vfs

export default function ChangeLocationForm({ loading, onCancel, onSubmit, initialValues = {} }) {
    const [params, setParams] = useState({})
    const [locationId, setLocationId] = useState()
    const productLoad = useLoad({
        url: PRODUCT_LIST,
        params: { ...params, productLocation: locationId }
    }, [params, locationId])
    const productItem = productLoad.response ? productLoad.response.results : []
    const products = productItem.map((item) => ({ ...item, quantity: '', count: item?.count || '', price: item?.price || '', priceSale: item?.priceSale || '', priceTransfer: item?.priceTransfer || '' }))
    const suppliers = useLoad({
        url: PRODUCT_LOCATION_LIST,
        params: { size: 1000, }
    })
    const [showProductCreate, hideProductCreate] = useModal((arrayHelpers) => <DocumentsProductCreate onCancel={() => hideProductCreate()} refresh={productLoad.request} arrayHelpers={arrayHelpers} />, styles.modal)
    const [showProductEdit, hideProductEdit] = useModal((props) => <DocumentsProductUpdate onCancel={() => hideProductEdit()} refresh={productLoad.request} item={props.item} arrayHelp={props.arrayHelp} />)
    const [showDeleteProduct, hideDeleteProduct] = useModal((item) => <DeleteModal onCancel={() => hideDeleteProduct()} refresh={productLoad.request} url={PRODUCT_DETAIL.replace('{id}', item?.id)} name={item?.name} />)

    const debouncedSearch = debounce((search) => setParams((prev) => ({ ...prev, search })), 500)
    const handleSearch = useCallback(({ target: { value } }) => debouncedSearch(value), [debouncedSearch])

    const handleSubmit = (vals, actions) => {
        const data = {
            ...vals,
            dateReturn: vals.dateReturn || undefined,
            invoice: vals.invoice ? +vals.invoice : undefined,
            wholesale: vals.wholesale ? +vals.wholesale : undefined,
            markup: vals.markup ? +vals.markup : undefined,
            vat: vals.vat ? +vals.vat : undefined,
            expenses: vals.expenses ? +vals.expenses : undefined,
        }
        onSubmit(data, actions)
    }

    const exportToPdf = () => {
        const data = JSON.parse(localStorage.getItem('locationItems')) || []

        const locationItems = data.map((item) => ({
            name: item.name,
            quantity: item.quantity,
            cars: item.car.map((car) => car.name).join(', '),
            category: item.selectCategory.name,
            barcode: item.barcode || ''
        }))

        const docDefinition = {
            content: [
                { text: 'СЧЕТ-ФАКТУРА', style: 'header' },
                { text: 'Информация о товарах', style: 'sectionTitle' },
                {
                    table: {
                        headerRows: 1,
                        widths: [20, 15, 140, 40, 110, 70, 70],
                        body: [
                            [
                                '',
                                { text: '№', style: 'tableHeader' },
                                { text: 'Наименование товара', style: 'tableHeader' },
                                { text: 'Остаток', style: 'tableHeader' },
                                { text: 'Количество', style: 'tableHeader' },
                                { text: 'Категория', style: 'tableHeader' },
                                { text: 'Штрих-код', style: 'tableHeader' },
                            ],
                            ...locationItems.map((item, index) => ['', index + 1, item.name, item.quantity, item.cars, item.category, item.barcode]),
                        ],
                    },
                },
            ],
            styles: {
                header: { fontSize: 14, bold: true, marginBottom: 10 },
                sectionTitle: { bold: true, marginTop: 10, marginBottom: 5 },
                tableHeader: { bold: true, fontSize: 8, color: 'black', fillColor: '#f3f3f3' },
            },
            defaultStyle: {
                fontSize: 10,
            },
        }

        pdfMake.createPdf(docDefinition).download('invoice.pdf')
    }

    return (
        <Formik
            enableReinitialize
            onSubmit={handleSubmit}
            initialValues={{
                products: JSON.parse(localStorage.getItem('locationItems')) || [],
                firstLocation: JSON.parse(localStorage.getItem('formDataLocation'))?.firstLocation || '',
                secondLocation: JSON.parse(localStorage.getItem('formDataLocation'))?.secondLocation || '', ...initialValues
            }}
        >
            {({ values, setFieldValue }) => {
                const totals = values.products.reduce(
                    (acc, product) => {
                        const quantity = product.quantity ? +product.quantity : 0
                        const price = product.price ? +product.price : 0
                        const priceSale = product.priceSale ? +product.priceSale : 0
                        acc.totalQuantity += quantity
                        acc.totalPurchaseSum += quantity * price
                        acc.totalSaleSum += quantity * priceSale
                        return acc
                    },
                    { totalQuantity: 0, totalPurchaseSum: 0, totalSaleSum: 0 },
                )

                return (
                    <Form>
                        <div style={{ padding: '1rem', boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.75)' }}>
                            <div className="columns">
                                <div className="column">
                                    <label>Место</label>
                                    <NewSupplierSelect
                                        disabled={JSON.parse(localStorage.getItem('locationItems'))?.length > 0}
                                        onChange={(e) => {
                                            setFieldValue('firstLocation', e?.id)
                                            const formDataLocation = JSON.parse(localStorage.getItem('formDataLocation')) || {}
                                            formDataLocation.firstLocation = e?.id
                                            setLocationId(e?.id)
                                            localStorage.setItem('formDataLocation', JSON.stringify(formDataLocation))
                                        }}
                                        value={suppliers?.response?.results?.find((item) => item.id === values?.firstLocation)}
                                        label="Поставщик*"
                                        validate={required}
                                        empty
                                        name="firstLocation"
                                        options={suppliers?.response?.results}
                                        placeholder="Выбор поставщика"
                                    />
                                </div>

                                <div className="column">
                                    <label>Место</label>
                                    <NewSupplierSelect
                                        onChange={(e) => {
                                            setFieldValue('secondLocation', e?.id)
                                            const formDataLocation = JSON.parse(localStorage.getItem('formDataLocation')) || {}
                                            formDataLocation.secondLocation = e?.id
                                            localStorage.setItem('formDataLocation', JSON.stringify(formDataLocation))
                                        }}
                                        value={suppliers?.response?.results?.find((item) => item.id === values?.secondLocation)}
                                        label="Поставщик*"
                                        validate={required}
                                        empty
                                        name="secondLocation"
                                        options={suppliers?.response?.results.filter((item) => item.id !== locationId)}
                                        placeholder="Выбор поставщика"
                                        disabled={JSON.parse(localStorage.getItem('locationItems'))?.length > 0}
                                    />
                                </div>
                            </div>
                        </div>
                        <br />

                        <Card>
                            <FieldArray
                                name="products"
                                render={(arrayHelpers) => {
                                    return (
                                        <>
                                            <Table
                                                showEmptyMessage={false}
                                                columns={{ name: 'Наименование товара', remains: 'Остаток', quantity: 'Количество', price_sale: 'Название автомобиля', measurement: 'Название бренда', price: 'Производитель', price_transfer: 'Категория', summ: 'Штрих-код', actions: '' }}
                                                items={values.products}
                                                renderItem={(item, index) => {
                                                    const productCurrency = item?.paymentType
                                                    const price = productCurrency === 'sum' ? item?.sumPrice : item?.usdPrice
                                                    const count = item?.quantity || 0

                                                    const sum = format(count * price)

                                                    console.log(price, count, sum, productCurrency, item?.quantity)
                                                    return (
                                                        <tr key={item.id}>
                                                            <td>{item.name}</td>
                                                            <td>{item.count}</td>
                                                            <td>
                                                                <Input
                                                                    type="number"
                                                                    validate={required}
                                                                    className="is-size-7"
                                                                    name={`products[${index}].quantity`}
                                                                    value={item.quantity || ''}
                                                                    onChange={(value) => {
                                                                        const newQuantity = value
                                                                        const parsedQuantity = parseFloat(newQuantity) || 0
                                                                        setFieldValue(`products[${index}].quantity`, parsedQuantity)
                                                                        const locallocationItems = JSON.parse(localStorage.getItem('locationItems')) || []
                                                                        const updatedlocationItems = locallocationItems.map((product) => (product.id === item.id ? {
                                                                            ...product,
                                                                            quantity: parsedQuantity
                                                                        } : product))
                                                                        localStorage.setItem('locationItems', JSON.stringify(updatedlocationItems))
                                                                    }}
                                                                    disabled={loading}
                                                                />
                                                            </td>
                                                            <td>
                                                                {' '}
                                                                {item?.car.map((car) => (
                                                                    <span className="is-light tag is-link mr-2 mb-2">{car.name}</span>
                                                                ))}
                                                            </td>
                                                            <td>{item?.selectBrand?.name}</td>
                                                            <td>{item?.selectManufacturer?.name}</td>
                                                            <td>{item?.category ? item.selectCategory.name : 'Другое'}</td>
                                                            <td>{item?.barcode}</td>
                                                            <td>
                                                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                                                    <Button
                                                                        onClick={() => {
                                                                            const locallocationItems = JSON.parse(localStorage.getItem('locationItems')) || []
                                                                            const result = locallocationItems.filter((e) => e.id !== item.id)
                                                                            localStorage.setItem('locationItems', JSON.stringify(result))
                                                                            arrayHelpers.remove(index)
                                                                        }}
                                                                        icon="close-outline"
                                                                        className="is-danger is-outlined is-size-7"
                                                                        disabled={loading}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }}
                                            />

                                            <div className="is-flex mt-6">
                                                <input type="search" className="input" onChange={handleSearch} />
                                            </div>

                                            {params.search && (
                                                <Table
                                                    columns={{ name: 'Наименование товара', car: 'Название автомобиля', brand: 'Название бренда', manufacturer: 'Производитель', category: 'Категория', code: 'Штрих-код', location: 'Местоположение', quantity: 'Количество', actions: '' }}
                                                    items={products.filter((prod) => !values.products.map((p) => p?.id).includes(prod?.id))}
                                                    renderItem={(item) => {
                                                        console.log(item, 'item')
                                                        return (
                                                            <tr key={item?.id}>
                                                                <td>{item?.name}</td>
                                                                <td>
                                                                    {item?.car.map((car) => (
                                                                        <span className="is-light tag is-link mr-2 mb-2">{car.name}</span>
                                                                    ))}
                                                                </td>
                                                                <td>{item?.selectBrand?.name}</td>
                                                                <td>{item?.selectManufacturer?.name}</td>
                                                                <td className="is-size-6">{item?.category ? item.selectCategory.name : 'Другое'}</td>

                                                                <td>{item?.barcode}</td>
                                                                <td>{item?.selectProductLocation?.name}</td>
                                                                <td>
                                                                    {item?.count} {getMeasurement(item?.measurement)}
                                                                </td>
                                                                <td>
                                                                    <Button
                                                                        onClick={() => {
                                                                            const locallocationItems = JSON.parse(localStorage.getItem('locationItems')) || []
                                                                            const productCurrency = item?.paymentType

                                                                            const bodyPrice = productCurrency === 'sum' ? item?.sumPrice : item?.usdPrice
                                                                            const SalePrice = productCurrency === 'sum' ? item?.sumPriceSale : item?.usdPriceSale
                                                                            const SaleOptomPrice = productCurrency === 'sum' ? item?.sumPriceTransfer : item?.usdPriceTransfer

                                                                            locallocationItems.push({
                                                                                ...item,
                                                                                price: bodyPrice,
                                                                                priceSale: SalePrice,
                                                                                priceTransfer: SaleOptomPrice
                                                                            })
                                                                            localStorage.setItem('locationItems', JSON.stringify(locallocationItems))
                                                                            arrayHelpers.push({ ...item, price: bodyPrice, priceSale: SalePrice, priceTransfer: SaleOptomPrice })
                                                                        }}
                                                                        icon="add-outline"
                                                                        className="is-size-7 is-outlined is-link"
                                                                        disabled={loading}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )
                                                    }}
                                                />
                                            )}
                                        </>
                                    )
                                }}
                            />

                            <div className="my-4">
                                <div className="is-pulled-right">
                                    <Button text="PDF" onClick={exportToPdf} className="is-link mr-3 is-outlined is-size-6" />
                                    <Button onClick={onCancel} icon="close-outline" text="Отмена" className="is-danger" disabled={loading} />
                                    &nbsp;&nbsp;
                                    <Button text="Сохранить" icon="add-outline" type="submit" className="is-success" disabled={loading} />
                                </div>
                            </div>

                            <br />
                        </Card>
                    </Form>
                )
            }}
        </Formik>
    )
}

const styles = StyleSheet.create({ modal: { width: 1000 } })
