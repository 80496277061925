import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import cn from 'classnames'
import Button from './common/Button'
import { getDateTime } from '../utils/date'
import ComponentToPrint from './Print'
import { format } from '../utils/number'
import { getPaymentMethods, getStatusTypes } from '../utils/types'

export default function ArchiveItem({
    item,
    index,
    // ordersList,
}) {
    // const orderRemove = useDeleteRequest({ url: ORDER_DETAIL.replace('{id}', item.id) })
    // eslint-disable-next-line max-len,no-return-assign
    const resultArchive = item.orderProduct.reduce((totalCost, currentValue) => {
        const count = parseInt(currentValue?.count, 10) || 0 // Assuming count is a property in your data structure
        const price = parseInt(currentValue?.price, 10) || 0 // Assuming price is a property in your data structure

        return totalCost + count * price
    }, 0)
    /*   async function onDelete() {
          if (global.confirm('Вы действительно хотите удалить?')) {
              await orderRemove.request()
              await ordersList.request()
          }
      } */

    // const [showViewModal, setShowViewModal] = useModal(
    //     <ViewCheckArchive
    //         result={resultArchive}
    //         item={item}
    //         hideModal={() => setShowViewModal()}
    //     />,
    // )
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })
    // const updateOrder = usePutRequest({ url: ORDER_DETAIL.replace('{id}', item.id) })

    // async function onSubmit(data) {
    //     const { success } = await updateOrder.request({
    //         data: {
    //             ...data,
    //             status: 'active',
    //             items: item.orderProduct,
    //             client: item.client.id,
    //         },
    //     })
    //     if (success) {
    //         ordersList.request()
    //     }
    // }
    return (
        <>
            <td>{index + 1}</td>
            <td className={cn(item?.status === 'successfully' ? 'has-text-black' : 'has-text-danger')}>{getStatusTypes(item?.status)}</td>
            <td>{item?.codeContent}</td>
            <td
            // onClick={showViewModal}
            >
                {item?.client?.name}
            </td>
            <td className="">{item?.client?.phoneNumber}</td>
            <td className="">
                {format(resultArchive)} {item?.currency === 'usd' ? 'USD' : 'Сум'}
            </td>
            <td className="">{getDateTime(item.createdAt)}</td>
            <td className="">{item.paymentMethod ? getPaymentMethods(item.paymentMethod) : ''}</td>
            <td>
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            handlePrint()
                        }}
                        className="button is-link mr-2 is-small is-outlined"
                        icon="print"
                    />
                    <div style={{ display: 'none' }}>
                        <ComponentToPrint result={item.totalForOrderHistories} item={item} ref={componentRef} />
                    </div>
                    {/* <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            onSubmit()
                        }}
                        className="button is-danger is-small is-outlined"
                        icon="trash" /> */}
                </div>
            </td>
        </>
    )
}
