import React from 'react'
import { NOTE_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import { useMessage } from '../hooks/message'
import NoteForm from './NoteForm'

export default function NoteUpdate({ hideModal, item, reload }) {
    const territoryUpdate = usePutRequest({ url: NOTE_DETAIL.replace('{id}', item.id) })
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const { success } = await territoryUpdate.request({ data })
        if (success) {
            showMessage('Успешно изменено', 'is-success')
            reload.request()
            hideModal()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered"><b>Изменить</b></h1>

            <NoteForm
                onCancel={hideModal}
                initialValues={{ ...item }}
                onSubmit={onSubmit} />
        </div>
    )
}
