import React from 'react'
import { useHistory } from 'react-router-dom'
import { usePostRequest } from '../hooks/request'
import { CHANGE_LOCATION } from '../urls'
import Layout from './Layout'
import ChangeLocationForm from './ChangeLocationForm'

export default function ChangeLocationCreate({ userList, territory }) {
    const market = usePostRequest({ url: CHANGE_LOCATION })
    const history = useHistory()

    async function onSubmit(data) {
        const { success } = await market.request({ data })

        if (success) {
            history.push('/cash-register')
            localStorage.removeItem('locationItems')
            localStorage.removeItem('formDataLocation')
        }
    }

    return (
        <Layout>
            <h3 className="title has-text-left">
                <b>Переместит</b>
            </h3>

            <ChangeLocationForm loading={market.loading} userList={userList} onSubmit={onSubmit} territory={territory} />
        </Layout>
    )
}
