import React from 'react'
import Button from './common/Button'
import { useModal } from '../hooks/modal'
import { useDeleteRequest } from '../hooks/request'
import { NOTE_DETAIL } from '../urls'
import NoteUpdate from './NoteUpdate'

export default function NoteItem({ item, reload, index }) {
    const territoryDelete = useDeleteRequest({ url: NOTE_DETAIL.replace('{id}', item.id) })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await territoryDelete.request()
            reload.request()
        }
    }

    const [showUpdateModal, setShowUpdateModal] = useModal(
        <NoteUpdate
            reload={reload}
            item={item}
            hideModal={() => setShowUpdateModal()}
        />,
    )

    return (
        <tr key={item.id}>
            <td className="is-size-5">{index + 1}</td>
            <td className="is-size-5">{item.note}</td>
            <td style={{ width: 400 }} className="is-size-5">
                <div dangerouslySetInnerHTML={{ __html: item.description }} />
            </td>
            <td className="has-text-right">
                <Button
                    onClick={showUpdateModal}
                    text="Изменить"
                    className="is-small is-primary mr-3"
                    icon="create-sharp" />

                <Button
                    onClick={onDelete}
                    text="Удалить"
                    className="is-small is-danger"
                    icon="create-sharp" />
            </td>
        </tr>
    )
}
